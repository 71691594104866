<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Diamonds</h4>
      </template>
      <template v-slot:body>
        <div class="d-flex" style="padding-top: 6px">
          <div class="card-box">
            <h5>Top-up</h5>
            <p class="m-0 price" v-if="walletData2 && walletData2.money">
              {{ walletData2.money }} Carat
            </p>
            <b-button variant="outline-primary" @click="handleClickOpenRecharge"
              >Top-up</b-button
            >
          </div>
          <div class="card-box">
            <h5>Received</h5>
            <p class="m-0 price" v-if="walletData1 && walletData1.money">
              {{ walletData1.money }} Carat
            </p>
            <!-- <b-button variant="none" class="iq-bg-primary">Withdraw</b-button> -->
          </div>
        </div>
        <p class="yellow-box">Diamonds can be used to reward member when you like his/her content.</p>
      </template>
    </iq-card>

    <!-- table -->
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Top Up" name="first">
          <el-table :data="diamonds" style="width: 100%">
            <el-table-column type="index" width="120" label="Serial number">
            </el-table-column>
            <el-table-column prop="order_on" label="Order Number">
            </el-table-column>
            <el-table-column prop="money" label="Amount of money">
            </el-table-column>
            <el-table-column prop="wallet_code" label="Source description">
            </el-table-column>
            <el-table-column prop="created_at" label="Creation time">
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <!-- <el-tab-pane label="Withdraw" name="second">
          <el-table :data="diamonds" style="width: 100%">
            <el-table-column type="index" width="120" label="Serial number">
            </el-table-column>
            <el-table-column prop="order_on" label="Order Number">
            </el-table-column>
            <el-table-column prop="money" label="Amount of money">
            </el-table-column>
            <el-table-column prop="wallet_code" label="Source description">
            </el-table-column>
            <el-table-column prop="created_at" label="Creation time">
            </el-table-column>
          </el-table>
        </el-tab-pane> -->
      </el-tabs>
    </el-card>

    <!-- 充值弹窗 -->
    <b-modal id="payPriceDiamonds" title="Recharge" centered hide-footer>
      <div>
        Amount:
        <input type="text" v-model="params.money" class="form-control" />
        <div class="d-flex justify-content-between">
          <span
            >Total:
            {{
              (params.money * ratio + params.money * ratio * service).toFixed(
                2
              ) || 0
            }}
            USD</span
          >
          <span>{{ ratio }} USD /Diamonds</span>
        </div>
      </div>
      <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="privacyPolicy"
          v-model="agree"
        />
        <label class="custom-control-label" for="privacyPolicy"
          >I accept
          <a href="javascript:;" @click="$router.push('/PrivacyAndPolicy?id=3')"
            >Payment Policy</a
          ></label
        >
      </div>
      <div class="mt-4 d-flex justify-content-end">
        <b-button variant="primary" @click="recharge" :disabled="!agree"
          >Confirm</b-button
        >
        <!-- <b-button class="ml-2" variant="primary" @click="testRecharge"
          >Test</b-button
        > -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import { year, month } from "@/Utils/getYearMonthDay";

import RealexHpp from "@/Utils/rxp-js";
import { mapGetters } from "vuex";
export default {
  props: ["walletData1", "walletData2", "diamonds"],
  data () {
    return {
      agree: false,
      activeName: "first",
      params: {
        money: "",
        type: 1,
      },
      ratio: 0, //汇率
      service: 0, //手续费
    };
  },
  computed: {
    yearList () {
      return year();
    },
    monthList () {
      return month();
    },
  },
  methods: {
    handleClick (tab, event) {
      // console.log(tab.name);
    },
    handleClickOpenRecharge () {
      this.diamondsRatio();
      this.params.money = "";
      this.agree = false;
      this.$bvModal.show("payPriceDiamonds");
    },
    // 钻石比例
    async diamondsRatio () {
      let res = await this.$http.diamondsRatio();
      if (res.status == 200) {
        this.ratio = res.data.diamond_9;
        this.service = res.data.diamond_7;
      }
    },
    // 余额充值
    testRecharge () {
      this.$http
        .testRecharge({ money: this.params.money, type: this.params.type })
        .then((res) => {
          if (res.status == 200) {
            this.$bvModal.hide("payPriceDiamonds");
            this.$router.go(0);
          }
        });
    },
    // 余额充值
    recharge () {
      this.$http
        .paypal({ money: this.params.money, type: this.params.type })
        .then((res) => {
          if (res.status == 200) {
            window.open(res.data.url, "_self");
            this.$bvModal.hide("payPrice");
            this.$setlocalStorage("PayId", res.data.order_id);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 567px) {
  .card-box {
    h5 {
      color: #999;
    }
    p {
      font-size: 20px;
      color: #333;
    }
    > .btn {
      width: 90px;
      height: 30px;
      margin: 30px 0;
      padding: 0;
    }
  }
}
// .form-control {
//   border: none !important;
//   border-bottom: 1px solid #ced4da !important;
//   border-radius: 0 !important;
// }
@media (min-width: 567px) {
  .card-box {
    width: 50%;
    h5 {
      color: #999;
    }
    p {
      font-size: 30px;
      color: #333;
      font-weight: SemiBold;
    }
    > .btn {
      width: 130px;
      height: 45px;
      margin: 30px 0;
    }
  }
}

.yellow-box {
  background: #fffcf3;
  color: #ffba68;
  padding: 27px 18px;
}
#payPriceDiamonds {
  .btns {
    .btn {
      div {
        height: 45px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active {
        background: #f5fbff;
        border: 1px solid #50b5ff;
        border-radius: 10px;
      }
    }
  }
  .currentBalance {
    font-family: montserrat;
    color: #999999;
    span {
      color: #50b5ff;
    }
  }
  .textYellow {
    text-align: right;
    color: #ffba68;
    line-height: 26px;
    cursor: pointer;
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  height: 45px;
  ::v-deep .el-input__inner {
    height: 100%;
    border-radius: 10px;
  }
}
</style>
