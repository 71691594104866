<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Top-up balance</h4>
      </template>
      <template v-slot:body>
        <div class="row d-flex justify-content-between">
          <div class="col-md-6 left d-flex">
            <div class="pt-2 img-box">
              <img
                style="display: block; width: 100%; height: auto"
                src="@/assets/images/page/wallet.png"
              />
            </div>
            <div v-if="walletData && walletData.money">
              <p class="m-0 pb-2 p-top">${{ walletData.money }}USD</p>
              <p class="mb-0 p-bottom">
                ~ {{ Math.round(walletData.money * 7.7516) }}HKD
              </p>
            </div>
          </div>
          <div class="right col-md-6">
            <b-button variant="outline-primary" @click="handleClickOpenRecharge"
              >Top-up</b-button
            >
            <!-- <b-button variant="outline-primary">Top-up</b-button> -->
          </div>
        </div>
        <p
          style="background: #fffcf3; color: #ffba68;padding:27px;18px;margin-top:10px;"
        >
          Topping up an account balance means adding money to the account so you have enough balance to cover for any future payments.
        </p>
      </template>
    </iq-card>

    <!-- table -->
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Transaction Records</h4>
      </template>
      <template v-slot:body>
        <el-table :data="balance" style="width: 100%">
          <el-table-column type="index" width="120" label="Serial number">
          </el-table-column>
          <el-table-column prop="order_on" label="Order Number">
          </el-table-column>
          <el-table-column prop="money" label="Amount of money">
          </el-table-column>
          <el-table-column prop="wallet_code" label="Source description">
          </el-table-column>
          <el-table-column prop="created_at" label="Creation time">
          </el-table-column>
        </el-table>
      </template>
    </iq-card>
    <!-- <div class="d-flex justify-content-end container" v-if="balance.length">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="12"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :total="total"
      >
      </el-pagination>
    </div> -->
    <!-- 充值弹窗 -->
    <b-modal id="payPrice" title="Top-up" centered hide-footer>
      <b-form>
        <b-form-group>
          <span class="appendAsterisk">Money</span>
          <b-form-input
            v-model="params.money"
            type="number"
            placeholder="Money"
            min="0"
            required
          ></b-form-input>
        </b-form-group>
        <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input
            type="checkbox"
            class="custom-control-input"
            id="privacyPolicy"
            v-model="agree"
          />
          <label class="custom-control-label" for="privacyPolicy"
            >I accept
            <a
              href="javascript:;"
              @click="$router.push('/PrivacyAndPolicy?id=3')"
              >Payment Policy</a
            ></label
          >
        </div>
      </b-form>
      <div class="mt-4 d-flex justify-content-end">
        <b-button
          variant="primary"
          v-if="showBtn"
          class="btnOrange mr-2"
          @click="$bvModal.hide('payPrice')"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="btnOrange mr-2"
          @click="cancelRe"
        >
          Cancel
        </b-button>
        <b-button variant="primary" @click="payPal()" :disabled="!agree"
          >Submit</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { year, month } from "@/Utils/getYearMonthDay";

export default {
  props: ["", ""],
  data () {
    return {
      showBtn: true,
      balance: [],
      walletData: {},
      page: 1,
      total: 0,
      cardType: [
        { id: "001", name: "Visa" },
        { id: "002", name: "MasterCard" },
      ],
      params: {
        type: 0, // 0余额，1钻石
        card_number: "",
        card_type: "",
        first_name: "",
        last_name: "",
        cvNumber: "",
        year: "",
        month: "",
        email: "",
        country: "",
        state: "",
        city: "",
        street: "",
        postalCode: "",
        money: "",
      },
      actionCode: "", // 100
      accessToken: "",
      url475: "", // 475
      token475: "",
      userId: "",
      agree: false,
      orderId: "",
      transactionId: "",
      isTip: false,
      isJava: Boolean,
      isJavaScript: false,
      colorDeep: "",
      language: "",
      height: "",
      width: "",
      time: "",
    };
  },
  beforeMount () {
    // window.addEventListener(
    //   "message",
    //   (event) => {
    //     // console.log(event);
    //     // {MessageType: "profile.completed", Session Id: "0_57f063fd-659a-4779-b45b-9e456fdb7935", Status: true}
    //     if (
    //       event.origin === "https://centinelapi.cardinalcommerce.com" ||
    //       event.origin === "https://centinelapistag.cardinalcommerce.com"
    //     ) {
    //       // if (event.origin === "https://centinelapistag.cardinalcommerce.com") {
    //       let data = JSON.parse(event.data);
    //       // console.log("Merchant received a message:", data);
    //       if (data.Status == true) {
    //         if (this.params.cvNumber) {
    //           this.recharge();
    //           this.params.cvNumber = "";
    //         }
    //       }
    //     }
    //     if (event.data !== undefined && event.data.Status) {
    //       // console.log("Songbird ran DF successfully");
    //     }
    //   },
    //   false
    // );
  },
  computed: {
    yearList () {
      let yearList = [];
      let year = new Date().getFullYear();
      for (let index = 0; index <= new Date().getYear(); index++) {
        yearList.push(year + index);
      }
      return yearList;
    },
    monthList () {
      return month();
    },
  },
  methods: {
    handleCurrentChange () { },
    getWalletInfo (type) {
      this.$http
        .walletAllInfo({
          type: type,
        })
        .then((res) => {
          // console.log('wellat', res);
          if (type == 1) {
            this.balance = res.data.list;
          }
          res.data.user_wallet.forEach((v) => {
            if (v.type == 1) {
              this.walletData = v;
            }
          });
        });
    },
    // 取消充值
    cancelRe () {
      location.reload();
    },
    tipBlock () {
      this.isTip = true;
    },
    tipNone () {
      this.isTip = false;
    },
    getBlowes () {
      this.isJava = navigator.javaEnabled(); // 启用Java
      this.isJavaScript = true; // 是否启用JavaScript
      this.language = navigator.language; // 浏览器语言
      this.width = document.documentElement.clientWidth; // 屏幕宽度
      this.height = document.documentElement.clientHeight; // 屏幕高度
      this.colorDeep = screen.colorDepth; // 颜色深度
      this.time = new Date(); // 时间
    },
    getUserInfo () {
      this.$http.getUserInfo().then((res) => {
        if (res.status == 200) {
          this.userId = res.data.user_info.user_id;
          this.params.email = res.data.email;
          this.params.first_name = res.data.user_info.first_name;
          this.params.last_name = res.data.user_info.last_name;
        }
      });
    },
    payPal () {
      this.$http
        .paypal({ money: this.params.money, type: this.params.type })
        .then((res) => {
          if (res.status == 200) {
            window.open(res.data.url, "_self");
            this.$bvModal.hide("payPrice");
            this.$setlocalStorage("PayId", res.data.order_id);
          }
        });
    },
    // payMent() {
    //   this.$http
    //     .payMent({
    //       isJavaScript: this.isJavaScript,
    //       isJava: this.isJava,
    //       language: this.language,
    //       width: this.width,
    //       height: this.height,
    //       color: this.colorDeep,
    //       time: this.time,
    //       type: this.params.type,
    //       email: this.params.email,
    //       cvNumber: this.params.cvNumber,
    //       country: this.params.country,
    //       state: this.params.state,
    //       city: this.params.city,
    //       street: this.params.street,
    //       postalCode: this.params.postalCode,
    //       car_num: this.params.card_number,
    //       first_name: this.params.first_name,
    //       last_name: this.params.last_name,
    //       month: this.params.month,
    //       year: this.params.year,
    //       card_type: this.params.card_type,
    //       money: this.params.money,
    //       user_id: this.userId,
    //     })
    //     .then((res) => {
    //       if (res.data) {
    //         // console.log(res.data);
    //         // this.accessToken = res.data.payerAuthSetupReply.accessToken;
    //         var ddcForm = document.querySelector("#ddc-form");
    //         ddcForm.action =
    //           res.data.payerAuthSetupReply.deviceDataCollectionURL;
    //         this.$refs.input.value = res.data.payerAuthSetupReply.accessToken;
    //         this.orderId = res.data.payment_order_id;
    //         if (ddcForm) {
    //           ddcForm.submit();
    //         }
    //       }
    //     });
    // },
    handleClickOpenRecharge () {
      this.$bvModal.show("payPrice");
      this.params.money = "";
      this.agree = false;
      this.getUserInfo();
      this.getBlowes();
    },
    polling () {
      let setPolling = setTimeout(() => {
        this.$http.payPolling({ order_id: this.orderId }).then((res) => {
          this.transactionId = res.data.transaction_id;
          if (res.data.status == "1") {
            clearTimeout(setPolling);
            this.$router.push({
              path: "/RecSuccess",
              query: {
                transactionId: this.transactionId,
                orderId: this.orderId,
              },
            });
          } else if (res.data.status == "-1") {
            clearTimeout(setPolling);
            this.$router.push({
              path: "/PayFailed",
              query: {
                transactionId: this.transactionId,
                orderId: this.orderId,
              },
            });
          } else {
            this.polling();
          }
        });
      }, 1000);
    },
    // 余额充值
    recharge () {
      this.$http
        .recharge({
          card_number: this.params.card_number,
          money: this.params.money,
          order_sn: this.orderId,
          type: 0,
        })
        .then((res) => {
          this.transactionId = res.data.transaction_id;
          if (res.status == 200) {
            this.$bvModal.hide("payPrice");
            this.$router.push({
              path: "/RecSuccess",
              query: {
                transactionId: this.transactionId,
                orderId: this.orderId,
              },
            });
          } else if (res.status == 475) {
            this.showBtn = false;
            // console.log(res.data);
            this.url475 = res.data.url;
            this.token475 = res.data.token;
            let stepUpForm = document.querySelector("#step-up-form");
            stepUpForm.action = res.data.url;
            // console.log(this.$refs.int);
            this.$refs.int.value = res.data.token;
            if (stepUpForm) {
              stepUpForm.submit();
              this.polling();
            }
          } else {
            this.$router.push({
              path: "/PayFailed",
              query: {
                transactionId: this.transactionId,
                orderId: this.orderId,
              },
            });
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.appendAsterisk::after {
  content: "*";
  color: red;
  display: inline;
  margin-left: 5px;
}
svg {
  position: relative;
  color: #ffba68;
}
.cvnTips {
  position: absolute;
  left: 110px;
  width: 75%;
  top: 200px;
  color: #ffba68;
  font-size: 12px;
  z-index: 999999;
  background: #fffcf3;
  padding: 5px;
  border: 1px solid #ffba68;
  border-radius: 5px;
}
@media (min-width: 992px) {
  .left {
    > .img-box {
      width: 40px;
      margin-right: 30px;
    }
    .p-top {
      font-size: 30px;
      color: #333;
      font-weight: SemiBold;
    }
    .p-bottom {
      border-top: 1px solid #dee2e6;
      padding: 20px 0;
      color: #999;
    }
  }
  .right {
    display: flex;
    justify-content: flex-end;
    > .btn {
      width: 130px;
      height: 45px;
      margin-right: 12px;
    }
  }
}
@media (max-width: 992px) {
  .left {
    > .img-box {
      width: 35px;
      margin-right: 15px;
    }
    .p-top {
      font-size: 20px;
      color: #333;
      font-weight: 600;
    }
    .p-bottom {
      border-top: 1px solid #dee2e6;
      padding: 20px 0;
      color: #999;
    }
  }
  .right {
    display: flex;
    justify-content: flex-start;
    > .btn {
      width: 130px;
      height: 45px;
      margin-left: 50px;
    }
  }
}
#payPrice {
  .btns {
    .btn {
      div {
        height: 45px;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active {
        background: #f5fbff;
        border: 1px solid #50b5ff;
        border-radius: 10px;
      }
    }
  }
  .currentBalance {
    font-family: montserrat;
    color: #999999;
    span {
      color: #50b5ff;
    }
  }
  .textYellow {
    text-align: right;
    color: #ffba68;
    line-height: 26px;
    cursor: pointer;
  }
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  height: 45px;
  ::v-deep .el-input__inner {
    height: 100%;
    border-radius: 10px;
  }
}
</style>
