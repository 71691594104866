<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">Reward Points</h4>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between" style="padding-top: 6px">
          <div class="left d-flex justify-content-between">
            <div class="pt-2" style="width: 35px; margin-right: 30px">
              <img style="display: block; width: 100%; height: auto" src="@/assets/images/page/reward.png" />
            </div>
            <div v-if="walletData && walletData.money">
              <p class="m-0 pb-2" style="font-size: 30px; color: #333">
                {{ walletData.money }} Points
              </p>
            </div>
          </div>
        </div>
        <p class="yellow-box">Reward points can be earned after participated events or courses. These points can be redeemed for reward in eshop.</p>
      </template>
    </iq-card>

    <!-- table -->
    <iq-card class="px-3">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Transaction Records" name="first">
          <el-table :data="reward" style="width: 100%">
            <el-table-column type="index" width="50" label="#"></el-table-column>
            <el-table-column prop="type_desc" label="Type"></el-table-column>
            <el-table-column prop="code_desc" label="Task"></el-table-column>
            <el-table-column prop="points" label="Points"></el-table-column>
            <el-table-column prop="title" label="Title">
              <template slot-scope="scope">
                <span>{{scope.row.title?scope.row.title:'-'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_time" label="Created Time"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Points Product Records" name="second">
          <el-table :data="pointList" style="width: 100%">
            <el-table-column type="index" width="120" label="Serial number">
            </el-table-column>
            <el-table-column prop="order_on" label="Order Number">
            </el-table-column>
            <el-table-column prop="money" label="Points" width="120">
              <template slot-scope="scope">
                <span>{{scope.row.code==100020?`+${scope.row.money.split('.')[0]}`:`-${scope.row.money.split('.')[0]}`}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="wallet_code" label="Source description">
            </el-table-column>
            <el-table-column prop="created_at" label="Creation time">
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="pb-3 mt-3" style="text-align: right;" v-if="activeName=='first'">
        <el-pagination background layout="prev, pager, next" :total="total" :current-page="page" :page-size="size"
          @current-change="changePage" hide-on-single-page>
        </el-pagination>
      </div>
    </iq-card>
  </div>
</template>

<script>
  export default {
    props: ["walletData", "pointList"],
    data() {
      return {
        page: 1,
        total: 0,
        size: 12,
        reward: [],
        activeName: 'first'
      };
    },

    components: {},

    created() {
    },

    methods: {
      handleClick(tab, event) {
        // console.log(tab.name);
      },

      async _getPointList(page) {
        this.page = page || this.page
        let res = await this.$http.pointsList({ per_page: this.size, page: this.page })
        if (res.status == 200) {
          this.reward = res.data.data;
          this.total = res.data.total
        }
      },

      changePage(value) {
        this.page = value
        this._getPointList()
      }
    },
  };
</script>
<style lang="scss" scoped>
  .yellow-box {
    background: #fffcf3;
    color: #ffba68;
    padding: 27px 18px;
    margin-top: 40px;
  }
</style>